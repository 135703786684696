<template>
  <div class="legal">
  	<Nav />
  	<Breadcrumbs :items="crumbs"/>
    <div class="legal__main">
    	<h1 class="ml-0 mt-3">MEDEA MEDICAL PRODUCTS WEBSITE TERMS OF USE</h1>
			<hr>
			<div>
				
      	<h3>Agreement to Terms of Use</h3>
      	<p>These Terms and Conditions of Use (the "Terms of Use") apply to the Medea Medical Products website located at www.medeappe.com, and all associated sites linked to it by Medea, its subsidiaries and affiliates (the “Website”). The Website is the property of Medea Inc. dba Medea Medical Products (“Medea”). BY USING THE WEBSITE, YOU AGREE TO THESE TERMS OF USE. IF YOU DO NOT AGREE, DO NOT USE THE WEBSITE.</p>
        <p>Medea reserves the right, at its sole discretion, to modify, add or remove these Terms of Use, at any time. You are responsible to check these Terms of Use periodically for changes. Your continued use of the Website following the posting of any revisions will mean that you accept and agree to the changes. </p>
        <h3>Your Use of the Website</h3>
        <p>The Website is intended only for use by representatives of the State of California, other state of the United States, the United States federal government and any other local or U.S. governmental agency defined by State of California contract number 1-20-65-32S (the “Contract”).  All purchase orders are subject to written acceptance by Medea.</p>
        <p>You are granted a non-exclusive, non-transferable, revocable license to access and use the Website strictly in accordance with these Terms of Use.  As a condition of your use of the Website, you warrant to Medea that you will not use the Website for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes upon the rights of Medea or others.</p>
        <p>You may not use any automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website, or in any way reproduce or bypass the navigational structure or presentation of the Website or its content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available to you through the Website. Medea reserves the right to ban any such activity.</p>
        <p>You may not attempt to gain unauthorized access to any portion or feature of the Website, or any other systems or networks connected to the Website or to any Medea server, or to any of the services offered on or through the Website, by hacking, password “mining” or any other illegitimate means</p>
        <p>You may not probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Website, or any other customer of Medea, including any Medea account not owned by you, to its source, or exploit the Website or any service or information made available or offered by or through the Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Website</p>
        <p>You may not use the Website in any manner which could damage, disable, overburden, or impair the Website or interfere with any other party’s use and enjoyment of the Website.</p>
        <p>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on the Website, or with any other person’s use of the Website.</p>
        <p>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to Medea on or through the Website or any service offered on or through the Website. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</p>
        <h3>Other Terms and Conditions</h3>
        <p>Additional terms and conditions may apply to purchases of goods or services and to specific portions or features of the Website. Medea may make changes to any products or services offered on the Website, or to the applicable prices for any such products or services, at any time, without notice, pursuant to the Contract. The materials on the Website with respect to products and services may be out of date, and Medea makes no commitment to update the materials on the Website with respect to such products and services.</p>
        <p>All content on the Website, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Website is the property of Medea or its suppliers and is protected by trademark, copyright and other laws that protect intellectual property and proprietary rights.  You agree to observe and abide by all trademark, copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto</p>
        <h3>Accounts, Passwords and Security</h3>
        <p>Certain features or services offered on or through the Website may require you to open an account (including setting up a user ID and password). You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify Medea immediately of any unauthorized use of your account or password, or any other breach of security. You may be held liable for losses incurred by Medea or any other user of or visitor to the Website due to someone else using your ID, password or account as a result of your failing to keep your account information secure and confidential.</p>
        <p>You may not use anyone else’s ID, password or account at any time without the express permission and consent of the holder of that ID, password or account. Medea cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations.</p>
        <h3>Privacy</h3>
        <p>Medea’s Privacy Policy applies to use of this Website, and its terms are made a part of these Terms of Use by this reference. Additionally, by using the Website, you acknowledge and agree that internet transmissions are never completely private or secure. You understand that any message or information you send to the Website may be read or intercepted by others.</p>
        <h3>Links</h3>
        <p>This Website may contain links to other independent third-party web sites (“Other Sites” or “Linked Sites”). These Other Sites are provided solely as a convenience to our visitors. Such Other Sites are not under Medea’s control, and Medea is not responsible for and does not endorse the content of such Other Sites, including any information or materials contained on such Other Sites. You will need to make your own independent judgment regarding your interaction with these Other Sites</p>
        <h3>Disclaimers</h3>
        <p>MEDEA DOES NOT PROMISE THAT THE WEBSITE OR ANY CONTENT, SERVICE OR FEATURE OF THE WEBSITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE WEBSITE WILL PROVIDE SPECIFIC RESULTS. THE WEBSITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE WEBSITE IS SUBJECT TO CHANGE WITHOUT NOTICE. MEDEA CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE WEBSITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. MEDEA DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. MEDEA DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE WEBSITE AND/OR ANY MEDEA SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE WEBSITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST MEDEA FOR DISSATISFACTION WITH THE WEBSITE OR ANY CONTENT IS TO STOP USING THE WEBSITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.</p>
        <p>The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action.</p>
        <p>Medea reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or access to the Website, or any portion of the Website, for any reason; (2) to modify or change the Website, or any portion of the Website, and any applicable policies or terms; and (3) to interrupt the operation of the Website, or any portion of the Website, as necessary to perform routine or non-routine maintenance, error correction, or other changes.</p>
        <h3>Limitation of Liability</h3>
        <p>Except where prohibited by law, in no event will Medea be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost profits, even if Medea has been advised of the possibility of such damages.</p>
        <p>Some jurisdictions do not allow limitations of liability, so the foregoing limitation may not apply to you.</p>
        <h3>Indemnity</h3>
        <p>You agree to indemnify and hold Medea, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including reasonable attorney’s fees), relating to or arising out of your use of or inability to use the Website or services, your violation of these Terms of Use, your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations.</p>
        <h3>Violation of Terms of Use</h3>
        <p>Medea may disclose any information we have about you if we determine that such disclosure is necessary in connection with any investigation or complaint regarding your use of the Website, or to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) Medea’s rights or property, or the rights or property of visitors to or users of the Website, including Medea’s customers. Medea reserves the right at all times to disclose any information that Medea deems necessary to comply with any applicable law, regulation, legal process or governmental request. Medea also may disclose your information when it determines that applicable law requires or permits such disclosure, including exchanging information with other companies and organizations for fraud protection purposes.</p>
        <p>You acknowledge and agree that Medea may preserve any transmittal or communication by you with Medea through the Website or any service offered on or through the Website, and may also disclose such data if required to do so by law or Medea determines that such preservation or disclosure is reasonably necessary to (1) comply with legal process, (2) enforce these Terms of Use, (3) respond to claims that any such data violates the rights of others, or (4) protect the rights, property or personal safety of Medea, its employees, users of or visitors to the Website, and the public</p>
        <p>You agree that Medea may, in its sole discretion and without prior notice, terminate your access to the Website and/or block your future access to the Website if we determine that you have violated these Terms of Use or other agreements or guidelines which may be associated with your use of the Website. You also agree that any violation by you of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to Medea, for which monetary damages would be inadequate, and you consent to Medea obtaining any injunctive or equitable relief that it deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Medea may have at law or in equity.</p>
        <p>You agree that Medea may, in its sole discretion and without prior notice, terminate your access to the Website, for cause, which includes (but is not limited to) (1) requests by law enforcement or other government agencies, (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification of the Website or any service offered on or through the Website, or (4) unexpected technical issues or problems</p>
        <p>You agree that Medea will not be liable to you or to any third party for termination of your access to the Website as a result of any violation of these Terms of Use.</p>
        <h3>Governing Law; Dispute Resolution</h3>
        <p>You agree that all matters relating to your access to or use of the Website, including all disputes, will be governed by the laws of the United States and by the laws of the State of California without regard to its conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in Alameda County, California, and waive any objection to such jurisdiction or venue. No recovery may be sought or received for damages other than out-of-pocket expenses. In the event of any controversy or dispute between Medea and you arising out of or in connection with your use of the Website, the parties shall attempt, promptly and in good faith, to resolve any such dispute.</p>
        <h3>Arbitration</h3>
        <p>In the event you and Medea are not able to resolve any dispute between each other arising out of or concerning these Terms of Use, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association in Alameda County, California.  The arbitrator’s award shall be final and judgment may be entered upon it in any court having jurisdiction.  The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the arbitrator.  This arbitration provision shall survive the termination of these Terms of Use.</p>
        <h3>Class Action Waiver</h3>
        <p>Any arbitration under these Terms of Use will take place on an individual basis.  Class arbitrations and class/representative/collective/group arbitrations are not permitted.  THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH PARTY’S INDIVIDUAL CAPACITY. Further, unless you and Medea agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.</p>
        <h3>Miscellaneous</h3>
        <p>You may not use or export or re-export any Website content or any copy or adaptation of such content, or any product or service offered on the Website, in violation of any applicable laws or regulations.</p>
        <p>If any of the provisions of these Terms of Use are held by an arbitrator, court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use, so that these Terms of Use shall remain in full force and effect. These Terms of Use constitute the entire agreement between you and Medea with respect to your use of the Website, and any and all other written or oral agreements or understandings previously existing between you and Medea with respect to such use are hereby superseded and cancelled. Other than as provided in a purchase agreement you enter into with Medea, Medea will not accept any counter-offers to these Terms of Use, and all such offers are hereby categorically rejected. Medea’s failure to insist on or enforce strict performance of these Terms of Use shall not be construed as a waiver by Medea of any provision or any right it has to enforce these Terms of Use, nor shall any course of conduct between Medea and you or any other party be deemed to modify any provision of these Terms of Use. These Terms of Use shall not be interpreted or construed to confer any rights or remedies on any third parties.</p>
        <h3>Feedback and Information</h3>
        <p>Any feedback or information you provide related to Medea or the Website will be deemed to be non-confidential. Medea shall be free to use such information on an unrestricted basis.</p>
      </div>
    </div>
  <Footer />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'terms',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Home", to: { name: "home"}}
	    let step2 = { title: "Terms of Use", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  components: {
  	Nav,
  	Footer,
  	Breadcrumbs
  }
}
</script>